//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'maintenance-costs',
  data() {
    return {
      model: null,
      data: [],
      options: [],
      car: {
        image: ''
      }
    }
  },
  async preFetch({ store }) {
    try {
      await store.dispatch('versions/query', {
        'filters[where][showInMaintenancePrices][eq]': true,
        sort: 'model,name',
        with: 'carModel.carImage',
        limit: 1000
      })
    } catch (e) {
      throw e
    }
    return true
  },
  computed: {
    versions() {
      return this.$store.getters['versions/items']
    }
  },
  mounted() {
    this.versions.forEach(v => {
      let label = `${v.carModel.title} ${v.name}`
      let img =
        v.carModel.carImage && v.carModel.carImage.url
          ? v.carModel.carImage.url
          : ''
      this.options.push({
        value: v.id,
        label: label.toUpperCase(),
        image: img
      })
    })
    this.$axios
      .get(
        'maintenance-prices?filters[where][versionId][eq]=' +
          this.options[0].value +
          '&sort=km ASC, months ASC&limit=30'
      )
      .then(r => {
        this.data = r.data.items
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    change() {
      this.$axios
        .get(
          'maintenance-prices?filters[where][versionId][eq]=' +
            this.model.value +
            '&sort=km ASC, months ASC&limit=30'
        )
        .then(r => {
          this.data = r.data.items
          if (this.model.image !== '') {
            this.car.image = this.model.image
          } else {
            this.car.image = ''
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase()
        this.options = this.options.filter(
          v =>
            v
              .toString()
              .toLowerCase()
              .indexOf(needle) > -1
        )
      })
    }
  }
}
